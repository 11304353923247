export default [
  {
    name: "Week 0",
    startDate: "2023-08-26",
    endDate: "2023-08-27",
  },
  {
    name: "Week 1",
    startDate: "2023-08-31",
    endDate: "2023-09-05",
  },
  {
    name: "Week 2",
    startDate: "2023-09-07",
    endDate: "2023-09-10",
  },
  {
    name: "Week 3",
    startDate: "2023-09-14",
    endDate: "2023-09-17",
  },
  {
    name: "Week 4",
    startDate: "2023-09-21",
    endDate: "2023-09-24",
  },
  {
    name: "Week 5",
    startDate: "2023-09-28",
    endDate: "2023-10-01",
  },
  {
    name: "Week 6",
    startDate: "2023-10-04",
    endDate: "2023-10-08",
  },
  {
    name: "Week 7",
    startDate: "2023-10-10",
    endDate: "2023-10-15",
  },
  {
    name: "Week 8",
    startDate: "2023-10-17",
    endDate: "2023-10-22",
  },
  {
    name: "Week 9",
    startDate: "2023-10-24",
    endDate: "2023-10-29",
  },
  {
    name: "Week 10",
    startDate: "2023-10-31",
    endDate: "2023-11-05",
  },
  {
    name: "Week 11",
    startDate: "2023-11-07",
    endDate: "2023-11-12",
  },
  {
    name: "Week 12",
    startDate: "2023-11-14",
    endDate: "2023-11-19",
  },
  {
    name: "Week 13",
    startDate: "2023-11-21",
    endDate: "2023-11-26",
  },
  {
    name: "Week 14",
    startDate: "2023-12-01",
    endDate: "2023-12-03",
  },
  {
    name: "Week 15",
    startDate: "2023-12-09",
    endDate: "2023-12-10",
  },
  {
    name: "Bowls",
    startDate: "2023-12-16",
    endDate: "2024-01-09",
  },
];
