export default [
  {
    name: "HOF",
    startDate: "2023-08-03",
    endDate: "2023-08-04",
    skip: true,
  },
  {
    name: "Pre 1",
    startDate: "2023-08-10",
    endDate: "2023-08-14",
    skip: true,
  },
  {
    name: "Pre 2",
    startDate: "2023-08-17",
    endDate: "2023-08-22",
    skip: true,
  },
  {
    name: "Pre 3",
    startDate: "2023-08-24",
    endDate: "2023-08-28",
    skip: true,
  },
  {
    name: "Week 1",
    startDate: "2023-09-07",
    endDate: "2023-09-12",
  },
  {
    name: "Week 2",
    startDate: "2023-09-14",
    endDate: "2023-09-19",
  },
  {
    name: "Week 3",
    startDate: "2023-09-21",
    endDate: "2023-09-26",
  },
  {
    name: "Week 4",
    startDate: "2023-09-28",
    endDate: "2023-10-03",
  },
  {
    name: "Week 5",
    startDate: "2023-10-05",
    endDate: "2023-10-10",
  },
  {
    name: "Week 6",
    startDate: "2023-10-12",
    endDate: "2023-10-17",
  },
  {
    name: "Week 7",
    startDate: "2023-10-19",
    endDate: "2023-10-24",
  },
  {
    name: "Week 8",
    startDate: "2023-10-26",
    endDate: "2023-10-31",
  },
  {
    name: "Week 9",
    startDate: "2023-11-02",
    endDate: "2023-11-07",
  },
  {
    name: "Week 10",
    startDate: "2023-11-09",
    endDate: "2023-11-14",
  },
  {
    name: "Week 11",
    startDate: "2023-11-16",
    endDate: "2023-11-21",
  },
  {
    name: "Week 12",
    startDate: "2023-11-23",
    endDate: "2023-11-28",
  },
  {
    name: "Week 13",
    startDate: "2023-11-30",
    endDate: "2023-12-05",
  },
  {
    name: "Week 14",
    startDate: "2023-12-07",
    endDate: "2023-12-12",
  },
  {
    name: "Week 15",
    startDate: "2023-12-14",
    endDate: "2023-12-19",
  },
  {
    name: "Week 16",
    startDate: "2023-12-21",
    endDate: "2023-12-26",
  },
  {
    name: "Week 17",
    startDate: "2023-12-28",
    endDate: "2024-01-01",
  },
  {
    name: "Week 18",
    startDate: "2024-01-04",
    endDate: "2024-01-09",
  },
  {
    name: "Wild Card",
    startDate: "2024-01-13",
    endDate: "2024-01-16",
  },
  {
    name: "Div Round",
    startDate: "2024-01-20",
    endDate: "2024-01-22",
  },
  {
    name: "Conf Champ",
    startDate: "2024-01-28",
    endDate: "2024-01-29",
  },
  {
    name: "Pro Bowl",
    startDate: "2024-02-04",
    endDate: "2024-02-05",
    skip: true,
  },
  {
    name: "Super Bowl",
    startDate: "2024-02-11",
    endDate: "2024-02-12",
  },
];
